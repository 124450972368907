import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/login/font_3988566_9kjmkbt7mjg/iconfont.css'
import './assets/icon/font_cg1qu0c3sev/iconfont.css'
import './assets/icon/font_wmuwz6ez28m/iconfont.css'
import NProgress from "nprogress";

Vue.use(ElementUI)
Vue.config.devtools = true;
Vue.config.productionTip = false

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = [
    '/login',
    '/register'
] // no redirect whitelist

router.beforeEach((to, from, next) => {
    console.log('before')
    NProgress.start()
    const user = JSON.parse(sessionStorage.getItem('userInfo'));
    if (user !== null) {
        console.log('已经登录')
        console.log(to.path)
        //如果用户已经登录
        let isFirstMenu = to.path != '/' && to.path.split("/").length == 2 // 是否是一级菜单
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            const userRoleRouterInfo = JSON.parse(sessionStorage.getItem('userRoleRouterInfo'));
            console.log(userRoleRouterInfo)
            if (store.getters.getPermissionHandle) {
                console.log(to);
                if(to.path == '/'){
                  let firstPath = store.getters.addRouters[0].children[0].path;
                  console.log('根目录，跳转到',firstPath)
                  next({path:firstPath})
                }else if (isFirstMenu) {
                    for (let menu of store.getters.addRouters[0].children) {
                        console.log('menu', menu)
                        if ("/" + menu.path == to.path) {
                            console.log('rediret to ', menu.children[0].path)
                            next({path: to.path + "/" + menu.children[0].path})
                            break;
                        }
                    }
                } else {
                    next()
                }
            } else {
                //动态加载路由
                const uris = [user.userLevel]
                userRoleRouterInfo.routerBOS?.forEach(item => {
                    uris.push(item.uri)
                })
                store.dispatch("GenerateRoutes", uris).then(() => {
                    store.getters.addRouters.forEach(item => {
                        router.addRoute(item)
                    })
                    router.addRoute({
                        path: '/:catchAll(.*)',
                        redirect: '/'
                    })
                    console.log('动态加载路由完毕')
                    next({...to, replace: true})
                    NProgress.done()
                }).catch(e => {
                    Message.error(e.message)
                })
            }
        }
    } else {
        //未登录
        if (whiteList.indexOf(to.path) !== -1) {
            //要去白名单
            next()
        } else {
            //不是白名单，定位到登录界面
            next('/login')
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
