<template>
  <div id="LogInView">
    <div id="left">
      <div id="left-text">
      </div>
      <img id="left-img" src="../assets/login/f0e09149-4e57-4269-bac0-210d4928f183.webp">
    </div>
    <div id="right">
      <LogIn></LogIn>
    </div>
  </div>
</template>



<script>
import LogIn from "@/components/LogIn.vue";
export default {
  name: 'LogInView',
  components: {
    LogIn

  },
  data(){
    return{
      // leftImageUrl:'https://ai-community.oss-cn-beijing.aliyuncs.com/ai_community_dist/dist/static/img/log-bg.853816b.png'
    }
  }
}
</script>

<style scoped>

#LogInView{
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  display: flex;
}

#left{
  width: 70%;
  height: 100%;
}

#left-text{
  position: absolute;
  z-index: 99;
  left: 35%;
  top: 30%;
  transform: translateX(-50%);
  font-size: 2em;
  color: #40a9ff;
}

#left-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

#right{
  width: 30%;
  height: 100%;
}
</style>
