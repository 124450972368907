import Vue from 'vue'
import Router from 'vue-router'
import router from './index.js'

const original = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject){
  if (onResolve || onReject) return original.call(this, location, onResolve, onReject)
  return original.call(this, location).catch(err => err)
}

Vue.use(Router)

export const asyncRouterMap = [
  {
    path: '/',
    name: 'homeView',
    component: () => import('@/views/HomeView.vue'),
    meta:{
      uri: 'home'
    },
    children:[
      {
        path: 'owner',
        name: 'ownerView',
        index: '/owner',
        title: '房产业主中心',
        icon: 'el-icon-user',
        component: () => import('@/views/owner/OwnerView.vue'),
        // redirect: '/owner/ownerInfo',
        meta: {
          uri: 'owner'
        },
        children: [
          {
            path: 'ownerInfo',
            name: 'ownerInfoView',
            index: '/owner/ownerInfo',
            title: '房产业主信息',
            meta: {
              parent: '/owner',
              uri: 'owner_info'
            },
            component: () => import('@/views/owner/content/ownerInfoView.vue')
          },
          {
            path: 'takeover',
            name: 'takeoverView',
            index: '/owner/takeover',
            title: '接管验收',
            meta: {
              parent: '/owner',
              uri: 'takeover'
            },
            component: () => import('@/views/owner/content/TakeoverView.vue')
          },
          {
            path: 'handover',
            name: 'handoverView',
            index: '/owner/handover',
            title: '接房办理',
            meta: {
              parent: '/owner',
              uri: 'handover'
            },
            component: () => import('@/views/owner/content/HandoverView.vue')
          },
          {
            path: 'renovation',
            name: 'renovationView',
            index: '/owner/renovation',
            title: '装修办理',
            meta: {
              parent: '/owner',
              uri: 'renovation'
            },
            component: () => import('@/views/owner/content/RenovationView.vue')
          },
          {
            path: 'lease',
            name: 'leaseView',
            index: '/owner/lease',
            title: '租赁管理',
            meta: {
              parent: '/owner',
              uri: 'lease'
            },
            component: () => import('@/views/owner/content/LeaseView.vue')
          },
          {
            path: 'service',
            name: 'serviceView',
            index: '/owner/service',
            title: '服务记录',
            meta: {
              parent: '/owner',
              uri: 'service'
            },
            component: () => import('@/views/owner/content/Service.vue')
          },
          {
            path: 'serviceHandle',
            name: 'serviceHandleView',
            index: '/owner/serviceHandle',
            title: '服务办理',
            meta: {
              parent: '/owner',
              uri: 'service_handle'
            },
            component: () => import('@/views/owner/content/ServiceHandle.vue')
          },
          // {
          //   path: 'ownerInfo',
          //   name: 'ownerInfoView',
          //   title: '装修办理信息',
          //   meta: {
          //     parent: '/owner',
          //     uri: 'owner_info'
          //   },
          //   component: () => import('@/views/owner/content/ownerInfoView.vue')
          // }
        ]
      },
      {
        path: 'payment',
        name: 'paymentView',
        index: '/payment',
        title: '缴费业务中心',
        icon: 'el-icon-s-finance',
        component: () => import('@/views/payment/PaymentView.vue'),
        // redirect: '/work/repair',
        meta: {
          uri: 'payment'
        },
        children: [
          {
            path: 'bill',
            name: 'billView',
            index: '/payment/bill',
            title: '账单中心',
            meta: {
              parent: '/payment',
              uri: 'bill'
            },
            component: () => import('@/views/payment/content/billView.vue')
          },
          {
            path: 'notPayBill',
            name: 'notPayBillView',
            index: '/payment/notPayBill',
            title: '未收账单',
            meta: {
              parent: '/payment',
              uri: 'not_pay_bill'
            },
            component: () => import('@/views/payment/content/notPayBillView.vue')
          },
          {
            path: 'payBill',
            name: 'payBillView',
            index: '/payment/payBill',
            title: '已收账单',
            meta: {
              parent: '/payment',
              uri: 'pay_bill'
            },
            component: () => import('@/views/payment/content/payBillView.vue')
          },
          {
            path: 'waterMeter',
            name: 'waterMeterView',
            index: '/payment/waterMeter',
            title: '水表抄表',
            meta: {
              parent: '/payment',
              uri: 'water_meter'
            },
            component: () => import('@/views/payment/content/waterMeterView.vue')
          },
          {
            path: 'electricMeter',
            name: 'electricMeterView',
            index: '/payment/electricMeter',
            title: '电表抄表',
            meta: {
              parent: '/payment',
              uri: 'electric_meter'
            },
            component: () => import('@/views/payment/content/electricMeterView.vue')
          },
          {
            path: 'hotWaterMeter',
            name: 'hotWaterMeterView',
            index: '/payment/hotWaterMeter',
            title: '热水表抄表',
            meta: {
              parent: '/payment',
              uri: 'hot_water_meter'
            },
            component: () => import('@/views/payment/content/hotWaterMeterView.vue')
          },
        ]
      },
      {
        path: 'work',
        name: 'workView',
        index: '/work',
        title: '工作任务中心',
        icon: 'el-icon-printer',
        component: () => import('@/views/work/WorkView.vue'),
        // redirect: '/work/repair',
        meta: {
          uri: 'work'
        },
        children: [
          {
            path: 'repair',
            name: 'repairView',
            index: '/work/repair',
            title: '维修工作',
            meta: {
              parent: '/work',
              uri: 'repair'
            },
            component: () => import('@/views/work/content/RepairView.vue')
          },
          {
            path: 'maintain',
            name: 'maintainView',
            index: '/work/maintain',
            title: '维保工作',
            meta: {
              parent: '/work',
              uri: 'maintain'
            },
            component: () => import('@/views/work/content/MaintainView.vue')
          },
          {
            path: 'patrol',
            name: 'patrolView',
            index: '/work/patrol',
            title: '巡查工作',
            meta: {
              parent: '/work',
              uri: 'patrol'
            },
            component: () => import('@/views/work/content/PatrolView.vue')
          },
          {
            path: 'appointment',
            name: 'appointmentView',
            index: '/work/appointment',
            title: '预约工单',
            meta: {
              parent: '/work',
              uri: 'appointment'
            },
            component: () => import('@/views/work/content/AppointmentView.vue')
          },
        ]
      },
      {
        path: 'interaction',
        name: 'interactionView',
        index: '/interaction',
        title: '互动服务中心',
        icon: 'el-icon-phone-outline',
        component: () => import('@/views/interaction/InteractionView.vue'),
        // redirect:'/interaction/notice',
        meta: {
          uri: 'interaction',
        },
        children: [
          {
            path: 'new_resident',
            name: 'NewResident',
            index: '/interaction/new_resident',
            title: '新业主审核',
            meta: {
              parent: '/interaction',
              uri: 'new_resident'
            },
            component: () => import('@/views/interaction/NewResident.vue')
          },
          {
            path: 'notice',
            name: 'NoticeView',
            index: '/interaction/notice',
            title: '公告通知',
            meta: {
              parent: '/interaction',
              uri: 'notice'
            },
            component: () => import('@/views/interaction/NoticeView.vue')
          },
          {
            path: 'complain',
            name: 'ComplainView',
            index: '/interaction/complain',
            title: '投诉管理',
            meta: {
              parent: '/interaction',
              uri: 'complain'
            },
            component: () => import('@/views/interaction/ComplainView.vue')
          },
          {
            path: 'question',
            name: 'QuestionView',
            index: '/interaction/question',
            title: '问卷调查',
            meta: {
              parent: '/interaction',
              uri: 'question'
            },
            component: () => import('@/views/interaction/QuestionView.vue')
          },
          {
            path: 'vote',
            name: 'VoteView',
            index: '/interaction/vote',
            title: '投票管理',
            meta: {
              parent: '/interaction',
              uri: 'vote'
            },
            component: () => import('@/views/interaction/VoteView.vue')
          },
          {
            path: 'activity',
            name: 'ActivityView',
            index: '/interaction/activity',
            title: '客户动态',
            meta: {
              parent: '/interaction',
              uri: 'activity'
            },
            component: () => import('@/views/interaction/ActivityView.vue')
          },
          {
            path: 'event',
            name: 'EventView',
            index: '/interaction/event',
            title: '活动',
            meta: {
              parent: '/interaction',
              uri: 'event'
            },
            component: () => import('@/views/interaction/EventView.vue')
          },
          {
            path: 'forum',
            name: 'ForumView',
            index: '/interaction/forum',
            title: '业主之家',
            meta: {
              parent: '/interaction',
              uri: 'forum'
            },
            component: () => import('@/views/interaction/ForumView.vue')
          }
        ]
      },
      {
        path: 'companySetting',
        name: 'companySettingView',
        index: '/companySetting',
        title: '项目管理中心',
        icon: 'el-icon-office-building',
        component: () => import('@/views/companyManage/CompanySettingView.vue'),
        // redirect:'/companySetting/device_manage',
        meta: {
          uri: 'company_setting',
        },
        children: [
          {
            path: 'device_manage',
            name: 'DeviceManageView',
            index: '/companySetting/device_manage',
            title: '设备管理',
            meta: {
              parent: '/companySetting',
              uri: 'device_manage'
            },
            component: () => import('@/views/companyManage/content/DeviceManageView.vue')
          },{
            path: 'material_manage',
            name: 'MaterialManageView',
            index: '/companySetting/material_manage',
            title: '物资管理',
            meta: {
              parent: '/companySetting',
              uri: 'material_manage'
            },
            component: () => import('@/views/companyManage/content/MaterialManageView.vue')
          },{
            path: 'contract_manage',
            name: 'ContractManageView',
            index: '/companySetting/contract_manage',
            title: '合同管理',
            meta: {
              parent: '/companySetting',
              uri: 'contract_manage'
            },
            component: () => import('@/views/companyManage/content/ContractManageView.vue')
          },{
            path: 'submission_manage',
            name: 'SubmissionView',
            index: '/companySetting/submission_manage',
            title: '报送资料',
            meta: {
              parent: '/companySetting',
              uri: 'submission_manage'
            },
            component: () => import('@/views/companyManage/content/SubmissionView.vue')
          },{
            path: 'archive',
            name: 'ArchiveView',
            index: '/companySetting/archive',
            title: '项目档案',
            meta: {
              parent: '/companySetting',
              uri: 'archive'
            },
            component: () => import('@/views/companyManage/content/ArchiveView.vue')
          },{
            path: 'rule_manage',
            name: 'RuleView',
            index: '/companySetting/rule_manage',
            title: '规章制度',
            meta: {
              parent: '/companySetting',
              uri: 'rule_manage'
            },
            component: () => import('@/views/companyManage/content/RuleView.vue')
          },{
            path: 'study_material',
            name: 'StudyMaterialView',
            index: '/companySetting/study_material',
            title: '学习资料',
            meta: {
              parent: '/companySetting',
              uri: 'study_material'
            },
            component: () => import('@/views/companyManage/content/StudyMaterialView.vue')
          },{
            path: 'holiday_manage',
            name: 'HolidayView',
            index: '/companySetting/holiday_manage',
            title: '考勤管理',
            meta: {
              parent: '/companySetting',
              uri: 'holiday_manage'
            },
            component: () => import('@/views/companyManage/content/HolidayView.vue')
          },{
            path: 'bill',
            name: 'BillingView',
            index: '/companySetting/bill',
            title: '支出账本',
            meta: {
              parent: '/companySetting',
              uri: 'bill'
            },
            component: () => import('@/views/companyManage/content/BillingView.vue')
          },{
            path: 'flow',
            name: 'FlowView',
            index: '/companySetting/flow',
            title: '申请批复',
            meta: {
              parent: '/companySetting',
              uri: 'flow'
            },
            component: () => import('@/views/companyManage/content/FlowView.vue')
          }
        ]
      },
      {
        path:'projectSetting',
        name:'projectSettingView',
        index: '/projectSetting',
        title: '项目设置中心',
        icon: 'el-icon-files',
        component: () => import('@/views/projectSetting/ProjectSettingView.vue'),
        // redirect:'/projectSetting/baseInfo',
        meta: {
          uri: 'project_setting',
        },
        children: [
          {
            path: 'baseInfo',
            name: 'baseInfoView',
            index: '/projectSetting/baseInfo',
            title: '项目基础资料',
            meta: {
              parent: '/projectSetting',
              uri: 'base_info'
            },
            component: () => import('@/views/projectSetting/content/BaseInfoView.vue')
          },
          {
            path: 'projectSystemSetting',
            name: 'projectSystemSettingView',
            index: '/projectSetting/projectSystemSetting',
            title: '项目系统设置',
            meta: {
              parent: '/projectSetting',
              uri: 'project_system_setting'
            },
            component: () => import('@/views/projectSetting/content/ProjectSystemSettingView.vue')
          },
          {
            path: 'accountPermission',
            name: 'accountPermissionView',
            index: '/projectSetting/accountPermission',
            title: '账号权限',
            component: () => import('@/views/projectSetting/content/AccountPermissionView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'account_permission'
            }
          },
          {
            path: 'feeItemSetting',
            name: 'feeItemSettingView',
            index: '/projectSetting/feeItemSetting',
            title: '收费标准',
            component: () => import('@/views/projectSetting/content/FeeItemSettingView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'fee_item_setting'
            }
          },
          {
            path: 'takeoverSetting',
            name: 'takeoverSettingView',
            index: '/projectSetting/takeoverSetting',
            title: '接管验收设置',
            component: () => import('@/views/projectSetting/content/TakeoverSettingView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'takeover_setting'
            }
          },
          {
            path: 'handoverSetting',
            name: 'handoverSettingView',
            index: '/projectSetting/handoverSetting',
            title: '交房设置',
            component: () => import('@/views/projectSetting/content/HandoverSettingView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'handover_setting'
            }
          },
          {
            path: 'renovationSetting',
            name: 'renovationSettingView',
            index: '/projectSetting/renovationSetting',
            title: '装修设置',
            component: () => import('@/views/projectSetting/content/RenovationSettingView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'renovation_setting'
            }
          },
          {
            path: 'fileImport',
            name: 'fileImportView',
            index: '/projectSetting/fileImport',
            title: '档案导入',
            component: () => import('@/views/projectSetting/content/FileImportView.vue'),
            meta: {
              parent: '/projectSetting',
              uri: 'file_import'
            }
          }
        ]
      },
      {
        path:'statistics',
        name:'StatisticsView',
        index: '/statistics',
        title: '报表统计中心',
        icon: 'el-icon-s-data',
        component: () => import('@/views/statistics/StatisticsView.vue'),
        meta:{
          uri: 'statistics'
        },
        children: [
          {
            path: 'houseStatistics',
            name: 'houseStatisticsView',
            index: '/statistics/houseStatistics',
            title: '房产报表',
            component: () => import('@/views/statistics/content/houseStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'house_statistics'
            }
          },
          {
            path: 'residentStatistics',
            name: 'residentStatisticsView',
            index: '/statistics/residentStatistics',
            title: '业主报表',
            component: () => import('@/views/statistics/content/residentStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'resident_statistics'
            }
          },
          {
            path: 'orderStatistics',
            name: 'orderStatisticsView',
            index: '/statistics/orderStatistics',
            title: '工单报表',
            component: () => import('@/views/statistics/content/orderStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'order_statistics'
            }
          },
          {
            path: 'interactionStatistics',
            name: 'interactionStatisticsView',
            index: '/statistics/interactionStatistics',
            title: '互动统计',
            component: () => import('@/views/statistics/content/interactionStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'interaction_statistics'
            }
          },
          {
            path: 'billStatistics',
            name: 'billStatisticsView',
            index: '/statistics/billStatistics',
            title: '账单统计',
            component: () => import('@/views/statistics/content/billStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'bill_statistics'
            }
          },
          {
            path: 'carStatistics',
            name: 'carStatisticsView',
            index: '/statistics/carStatistics',
            title: '车辆统计',
            component: () => import('@/views/statistics/content/carStatisticsView.vue'),
            meta: {
              parent: '/statistics',
              uri: 'car_statistics'
            }
          },
        ]
      },
      {
        path:'systemSetting',
        name:'systemSettingView',
        index: '/systemSetting',
        title: '系统设置中心',
        icon: 'el-icon-setting',
        component: () => import('@/views/systemSetting/SystemSettingView.vue'),
        // redirect: '/systemSetting/systemInfo',
        meta:{
          uri: 'system_setting'
        },
        children: [
          {
            path: 'systemInfo',
            name: 'systemInfoView',
            index: '/systemSetting/systemInfo',
            title: '系统信息',
            component: () => import('@/views/systemSetting/content/SystemInfoView.vue'),
            meta: {
              parent: '/systemSetting',
              uri: 'system_info'
            }
          },
          {
            path: 'systemStudyFile',
            name: 'systemStudyFile',
            index: '/systemSetting/systemStudyFile',
            title: '系统资料',
            component: () => import('@/views/systemSetting/content/SystemStudyFile.vue'),
            meta: {
              parent: '/systemSetting',
              uri: 'system_study_file'
            }
          }
        ]
      }
    ]
  }
]

export const adminRouterMap = [
  {
    path: 'projectManage',
    name: 'projectManageView',
    index: '/systemSetting/projectManage',
    title: '项目管理',
    component: () => import('@/views/systemSetting/content/ProjectManageView.vue'),
    meta: {
      parent: '/systemSetting',
      uri: 'project_manage'
    }
  },
]

export const constantRouterMap = [
  {
    path: '/login',
    name: 'logInView',
    component: () => import('@/views/LogInView.vue')
  },
  {
    path: '/register',
    name: 'registerView',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/404',
    name: '404',
    hidden: true,
    component: () => import('@/views/404.vue')
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})


