<template>
  <div id="LogIn">
    <div id="log-in-content">
      <div id="log-in-content-title">
        <div id="title-son">
          <img id="login-logo" src="../assets/LongLogo.jpg">
        </div>
        <div id="login-text">服务电话：0874-3126088  16608740777</div>
      </div>
      <div v-if="user.logMethod === 1">
        <div class="log-input">
          <el-input
              placeholder="请输入账号"
              v-model="user.account"
              maxlength="13"
          ></el-input>
        </div>
        <div class="log-input">
          <el-input
              placeholder="请输入密码"
              :type="type" v-model="user.password"
              show-password
          >
          </el-input>
        </div>
      </div>
      <div v-if="user.logMethod === 2">
        <div class="log-input">
          <el-input
              placeholder="请输入手机号"
              v-model="user.phone"
              maxlength="13"
          ></el-input>
        </div>
        <div id="verify-code" class="log-input">
            <el-input
                style="width: 60%"
                placeholder="请输入验证码"
                v-model="user.verifyCode"
            ></el-input>
            <el-button id="btn"
                       style="width: 35%"
                       @click="getVerifyCode"
                       :disabled="buttonDisable">
              获取验证码
            </el-button>
        </div>

      </div>
      <div class="two-button"
           @keyup.enter="keyDown()"
      >
<!--        <div-->
<!--            class="register"-->
<!--            @click="goToRegister"-->
<!--        >-->
<!--          暂无账号，点击注册-->
<!--        </div>-->
        <div class="change-log-method" v-if="user.logMethod === 1" @click="() => this.user.logMethod = 2">
          切换为验证码登录
        </div>
        <div class="change-log-method" v-if="user.logMethod === 2" @click="() => this.user.logMethod = 1">
          切换为账号登录
        </div>
      </div>
      <div id="log-in-content-button" @click="login">
        登录
      </div>
      <span style="color: grey;cursor: pointer;position: fixed; bottom: 10px; font-size: 14px;" @click="gotoBeiAn">滇ICP备2024027824号-2</span>
      
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import {Message} from "element-ui";
import {getUserInfo} from "@/api/logIn";
import {sendVerifyCode} from "@/api/register";
import user from "@/store/modules/user";

export default {
  name: "LogIn",
  data() {
    return {
      user: {
        account: "",
        password: "",
        mdPassword: "",
        phone: '',
        verifyCode: '',
        logMethod: 1,
      },
      type: "password",
      buttonDisable: false,
    }
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    goToRegister() {
      this.$router.push('/register')
    },
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.login(); // 定义的登录方法
      }
    },
    getVerifyCode() {
      const phone = this.user.phone;
      var phoneRegex = /^1[3456789]\d{9}$/;
      if (phoneRegex.test(phone)) {
        sendVerifyCode(phone,'2122910','').then(res => {
          if (res.data.success) {
            Message.success("发送成功");
          }
        });
        this.startCountdown();
      } else {
        Message.error("请输入合法的手机号");
      }
    },
    startCountdown() {
      let btn = document.getElementById('btn');
      let time = 60; //剩下的秒数
      this.buttonDisable = true;
      this.timer = setInterval(() => {
        if (time === 0) {
          // 清除定时器和复原按钮
          clearInterval(this.timer);
          this.buttonDisable = false;
          btn.innerHTML = '获取短信验证码';
        } else{
          time --;
          btn.innerHTML = time + '秒后重发';
        }
      }, 1000);
    },
    login() {
      // 验证
      if (this.user.logMethod === 1){
        if (this.user.account.length === 0) {
          this.$message.error("请填写账号")
          return;
        }
        if (this.user.password.length === 0) {
          this.$message.error("请填写密码")
          return;
        }
        this.user.mdPassword = md5(this.user.password)
      } else if (this.user.logMethod === 2){
        console.log('验证码校验')
        if (this.user.phone.length === 0) {
          this.$message.error("请填写手机号")
          return;
        }
        if (this.user.verifyCode.length === 0) {
          this.$message.error("请填写验证码")
          return;
        }
      }
      // this.user.password = '';
      this.$store.dispatch('LogIn', this.user).then(() => {
        console.log('登录完毕')
        console.log('开始获取用户信息')
        const user = JSON.parse(sessionStorage.getItem('user'));
        getUserInfo(user.userId).then(res => {
          if (res.data.success) {
            var data = res.data.data;
            sessionStorage.setItem('userInfo', JSON.stringify(data))
            const userRoleRouterInfos = data.userRoleRouterInfos;
            if (userRoleRouterInfos) {
              const expireRole = userRoleRouterInfos.filter(item => item.expire);
              const noExpireRole = userRoleRouterInfos.filter(item => !item.expire);
              const threeMonthRole = userRoleRouterInfos.filter(item => item.threeMonth);
              //提示过期的
              if (expireRole) {
                expireRole.forEach(item => {
                  const communityName = item.communityName;
                  let message = `您好，您的小区：${communityName}已经过期，请联系软件服务方进行续费后方可使用，联系方式：0874-3126088，16608740777。`;
                  this.warning(message);
                })
              }
              if (threeMonthRole) {
                threeMonthRole.forEach(item => {
                  const communityName = item.communityName;
                  let message = `您好，您的小区：${communityName}即将过期，请及时联系软件服务方进行续费，联系方式：0874-3126088，16608740777。`;
                  this.warning(message);
                })
              }
              if (noExpireRole) {
                sessionStorage.setItem('userRoleRouterInfo', JSON.stringify(noExpireRole[0]));
                // this.$router.push({path: '/'})
                const origin = window.location.origin;
                console.log('origin',origin)
                window.open(origin,'_self')
              } else {
                sessionStorage.clear();
                this.$router.push({path: '/login'})
              }
            }
          }
        })
      }).catch(e => {
        // Message.error('账号或者密码错误')
      })
    },
    warning(message) {
      this.$notify({
        title: '警告',
        message: message,
        type: 'warning'
      });
    },
    
    gotoBeiAn(){
      window.open("https://beian.miit.gov.cn/")
    },
  }
}
</script>

<style>
#LogIn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* 垂直居中 */

  #log-in-content {
    height: 60%;
    width: 70%;
    margin: auto;

    #log-in-content-title {
      //display: flex;

      #title-son {
        height: 20%;
        #login-logo {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }

      #login-text{
        margin-bottom: 10px;
      }
    }

    .log-input {
      height: 10%;
      margin: auto;
      padding-bottom: 20px;
    }

    #verify-code{
      display: flex;
      justify-content: space-between;
    }

    .two-button {
      display: flex;
      margin: auto;
      font-size: small;
      justify-content: space-between;

      .register {
      }

      .register:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #409eff;
      }

      .change-log-method:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #409eff;
      }
    }

    #log-in-content-button {
      height: 10%;
      width: 50%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: #409eff;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
      margin: 3vh 0 0 0;
      background-size: cover;
      border-radius: 5px;
    }

    #log-in-content-button:hover {
      background: dodgerblue;
      color: white;
      transition: all 0.3s;
      cursor: pointer;
      background-size: cover;
      border-radius: 5px;
    }
  }
}









</style>