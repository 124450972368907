import router, {adminRouterMap, asyncRouterMap, constantRouterMap} from "@/router";


/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 * @returns {boolean}
 */
function hasPermission(roles, route){
    if (route.meta && route.meta.uri){
        return roles.indexOf(route.meta.uri) >= 0
    } else {
        return false
    }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
    return asyncRouterMap.filter(
        router => {
            if (hasPermission(roles, router)) {
                if (router.children && router.children.length) {
                    router.children = filterAsyncRouter(router.children, roles)
                }
                return true
            }
            return false;
        }
    )
}

/**
 * 将一级用户的特殊路由，分别加载到该路由对应的路经下
 * @param routeTree
 * @param route
 */
function addRouteToTree(routeTree, route){
    const parent = route.meta.parent;
    let find = routeTree.find(item => item.index === parent);
    if (!find){
        routeTree.forEach(item => {
            if (item.children){
                let childFind = item.children.find(child => child.index === parent);
                if (childFind){
                    find = childFind;

                }
            }
        })
    }
    if (find) {
        find.children.push(route);
    }
}


const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: [],
        userInfoArray: [],
        permissionHandle: false
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)
        },
        SET_USERINFO: (state, data) => {
            state.userInfoArray = data
        },
        SET_PERMISSION_HANDLE:(state, data) => {
            state.permissionHandle = data
        }
    },
    actions: {
        GenerateRoutes({ commit }, data) {
            console.log('开始动态处理路由')
            console.log(data)

            return new Promise(resolve => {
                const roles = data
                console.log(roles)
                let accessRouters
                if (['1', '2'].includes(roles[0])){
                    // 如果是一级、二级，赋予所有路有权限
                    accessRouters = asyncRouterMap
                    if ('1' === roles[0]){
                        adminRouterMap.forEach(item => addRouteToTree(accessRouters, item))
                    }
                } else {
                    //三级、四级用户，根据配置过滤路有权限
                    console.log('开始过滤路由')
                    accessRouters = filterAsyncRouter(asyncRouterMap, roles)
                }
                console.log('用户所拥有的路由')
                console.log(accessRouters)
                commit('SET_PERMISSION_HANDLE', true)
                commit('SET_ROUTERS', accessRouters)
                commit('SET_USERINFO', data)
                resolve()
            })
        }
    }
}

export default permission