import axios from "axios";
import {Message} from 'element-ui'


const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // 请求超时的时间
    timeout: 100000,
});

request.interceptors.request.use(
    (config) => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (user) {
            const token = user.token; // Replace 'your_token' with the actual token value
            if (token) {
                config.headers['token'] = `${token}`;
            }
        }

        return config;
    }
)

request.interceptors.response.use(res => {
    if (res.data.success) {
        return res;
    } else {
        if (res.data.message) {
            if (res.data.code == 1005) {
                Message.warning("登录已过期");
                // Router.call({path:'/login'})
            } else {
                Message.error(res.data.message);
                return Promise.reject(res.data.message);
            }
        } else
            return res;
    }
}, error => {
    if (error.response.status) {
        switch (error.response.status) {
            // case 401:
            //   window.localStorage.clear()
            //   router.push('/user')
            //   break;
            // case 402:
            //   window.localStorage.clear()
            //   location.reload();
            //   break;
            case 500:
                Message.error("未知错误");
        }
    }
    return Promise.reject(new Error(error));
})

//get下载文件方法
request.downloadFile = function (url, params, fileName) {
    return this({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params
    })
        .then(response => {
            // 从响应头部获取文件名或者从入参获取文件名
            const contentDisposition = response.headers['content-disposition'];
            let filenameRegEx = /filename=(.*)/;
            let matches = filenameRegEx.exec(contentDisposition);
            let fileNameMatch = matches ? matches[1] : null;
            let fileNameFromResponse = decodeURIComponent(fileNameMatch);
            let name;
            if (fileName) {
                name = fileName;
            } else {
                name = fileNameFromResponse;
            }

            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = href;
            link.download = name;
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(href);
        });
};

request.downloadFilePost = function (url, body, fileName) {
    return this({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: body
    })
        .then(response => {
            // 从响应头部获取文件名或者从入参获取文件名
            const contentDisposition = response.headers['content-disposition'];
            let filenameRegEx = /filename=(.*)/;
            let matches = filenameRegEx.exec(contentDisposition);
            let fileNameMatch = matches ? matches[1] : null;
            let fileNameFromResponse = decodeURIComponent(fileNameMatch);
            let name;
            if (fileName) {
                name = fileName;
            } else {
                name = fileNameFromResponse;
            }

            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = href;
            link.download = name;
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(href);
        });
};

request.printFile = function (url, params) {
    return this({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params
    })
        .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});

            var date = (new Date()).getTime()
            var ifr = document.createElement('iframe')
            ifr.style.frameborder = 'no'
            ifr.style.display = 'none'
            ifr.style.pageBreakBefore = 'always'
            ifr.setAttribute('id', 'printPdf' + date)
            ifr.setAttribute('name', 'printPdf' + date)
            ifr.src = window.URL.createObjectURL(blob)
            document.body.appendChild(ifr)
            ifr.onload = function() {
                let doc = ifr.contentWindow.document;

                // 创建并添加 <style> 元素到 iframe 的文档中
                let styleEle = doc.createElement('style');
                styleEle.innerHTML = `@page {size: A4;}`;
                doc.head.appendChild(styleEle);

                var ordonnance = ifr.contentWindow;
                setTimeout(() => {
                    ordonnance.print(); // 执行打印操作
                }, 100);
            };

            window.URL.revokeObjectURL(href);
        });
};

request.printFilePost = function (url, body) {
    return this({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: body
    })
        .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});

            var date = (new Date()).getTime()
            var ifr = document.createElement('iframe')
            ifr.style.frameborder = 'no'
            ifr.style.display = 'none'
            ifr.style.pageBreakBefore = 'always'
            ifr.setAttribute('id', 'printPdf' + date)
            ifr.setAttribute('name', 'printPdf' + date)
            ifr.src = window.URL.createObjectURL(blob)
            document.body.appendChild(ifr)

            var ordonnance = document.getElementById('printPdf' + date).contentWindow
            setTimeout(() => {
                // window.print()
                ordonnance.print()
            }, 100)

            window.URL.revokeObjectURL(href);
        });
};

export default request