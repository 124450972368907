import Vue from 'vue'
import Vuex from 'vuex'
import permission from "@/store/modules/permission";
import user from "@/store/modules/user";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    getUser: state => state.user,
    addRouters: state => state.permission.addRouters,
    getUserName: state => state.user.name,
    getPermissionHandle: state => state.permission.permissionHandle,
    getRouters: state => state.permission.routers
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    permission
  }
})
