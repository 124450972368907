<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
import LogInView from "@/views/LogInView.vue";
import {defineComponent} from "vue";

export default defineComponent({
  components: {LogInView}
})


</script>

<style>
#app{
  font-family: Avenir,Helvetica,Arial,sans-serif;
}
</style>
