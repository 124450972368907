import request from "@/api/request";


export var login = loginData => request.post(`/userLogController/login`, loginData)

export var changePassword = (id, password) => request.get(`/userLogController/changePassword?id=${id}&password=${password}`)

export var getUserInfo = userId => request.get(`/userLogController/getUserInfo?userId=${userId}`)
export var search = (name, department_id) => {
  let url = `/userLogController/search?name=${name}`;
  if (department_id) {
      url += `&department_id=${department_id}`;
  }
  return request.get(url);
}


export var querySearchUser = (queryString, cb) => {
    if(queryString === undefined)queryString = ''
    search(queryString).then(res=>{
      let data = [];
      for(let item of res.data.data){
        data.push({value:item.username,id:item.id})
      }
      cb(data)
    })  
  }

export var getDepartment = community_id => request.get(`/userLogController/department?community_id=${community_id}`)
export var querySearchDept = (community_id, cb) => {
  getDepartment(community_id).then(res=>{
    let data = [];
    for(let item of res.data.data){
      data.push({value:item.name,id:item.id})
    }
    cb(data)
  })  
}

export var getUserDept = community_id => request.get(`/userLogController/userDept?communityId=${community_id}`)
export var getDeptAndUser = community_id => request.get(`/userLogController/getDeptAndUser?communityId=${community_id}`)
export var getUserNoDept = community_id => request.get(`/userLogController/userNoDept?communityId=${community_id}`)

// 同时搜索员工和住户
export var searchUserResident = (community_id,search) => request.get(`/userLogController/userResident`,{params:{communityId:community_id,search}})