import {getUserInfo, login} from "@/api/logIn";


const user = {
    state: {
        name: '',
        roles: []
    },
    mutations: {
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        }
    },
    actions: {
        LogIn({ commit }, loginData) {
            console.log('开始登录')
            return new Promise((resolve, reject) => {
                login(loginData).then(res => {
                    if (res.data.success) {
                        console.log(res.data)
                        console.log('登录成功')
                        let data = res.data.data
                        if (typeof data === 'string'){
                            data = JSON.parse(data)
                        }
                        sessionStorage.setItem('user', JSON.stringify(data))
                        commit('SET_NAME', data.username)
                        resolve()
                    } else {
                        console.log('登录失败')
                        reject()
                    }
                }).catch(
                    error => {
                        reject(error)
                    }
                )
            })
        }
    }

}

export default user